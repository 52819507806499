import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image"
import { FluidImage } from "@components/Image"
import {useTranslation} from 'react-i18next'

import Link from "@components/Link"

import css from "@css/components/blocks/error/Error404.module.styl"

export default function Error404()
{
    const {t} = useTranslation()

    const q = useStaticQuery(graphql`
        query Error404Query {
            c00 : file(relativePath: { eq: "Error404__img__chara00@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                } }
            }
            c01 : file(relativePath: { eq: "Error404__img__chara01@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                } }
            }
            c02 : file(relativePath: { eq: "Error404__img__chara02@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                } }
            }
            c03 : file(relativePath: { eq: "Error404__img__chara03@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                } }
            }
            c04 : file(relativePath: { eq: "Error404__img__chara04@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                } }
            }
            c05 : file(relativePath: { eq: "Error404__img__chara05@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                } }
            }
            c06 : file(relativePath: { eq: "Error404__img__chara06@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                } }
            }
            c07 : file(relativePath: { eq: "Error404__img__chara07@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                } }
            }
            c08 : file(relativePath: { eq: "Error404__img__chara08@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                } }
            }
            c09 : file(relativePath: { eq: "Error404__img__chara09@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                } }
            }
        }
    `)

    const [randomImage, setRandomImage] = React.useState(null)

    const getRandomInt = (max) => {
        return Math.floor(Math.random() * Math.floor(max))
    }

    React.useEffect(()=>{
        const keys = Object.keys(q)
        const max = keys.length
        const rand = getRandomInt(max)
        setRandomImage(q[keys[rand]])
    },[])


    return(
            <div className={css.container} id="error404">
                <h1 className={css.title}>
                    404
                </h1>
                <figure className={css.chara}>
                    { randomImage && <FluidImage data={randomImage}/> }
                </figure>
                <div className={css.message}>
                    <p className={css.l1}>
                        {t('error404__title')}
                    </p>
                    <p className={css.l2}>
                        {t('error404__text')}
                    </p>
                </div>
                <Link to="/" className={css.button}>
                    {t('error404__button')}
                </Link>
            </div>
        )
}
