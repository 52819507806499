import React from "react"
import { graphql } from "gatsby"
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import SEO from "@components/Seo"
import Error404 from "@components/blocks/error/Error404"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"

import css from "@css/components/blocks/index/Index.module.styl"

const ThreeJs = loadable( () => import('@three/Main.js'))

export default function Error(props)
{
	const {t} = useTranslation()

	React.useEffect(()=>{
	})

	return (
		<React.Fragment>
			{/*<ThreeJs/>*/}
			<SEO title={`404 NOT FOUND`} pathname="/404" />
			<main className={css.container} id="main">
				<Error404/>
				<FooterSns/>
			</main>
			<Footer/>
		</React.Fragment>
	)
}
